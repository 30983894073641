<template>
    <div v-if="showdialog" class="dialogview" >
        <transition name="fade" class="loading">
            <div>                
                <div class="dialogbox">                    
                    <h5><b>Ankaufstation Filter bearbeiten</b></h5>                                        
                    <br>

                    <div class="row">                        
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                            <select class="form-control" disabled="true" name="makes" v-model="selected">                                                                    
                                <option :value="undefined" disabled>{{ inmarke }}</option>
                                <!-- <option v-for="make in this.data.makedata" :key="make.makeid" :value="make.makeid" :selected="make.makeid === inmarkeid">{{ make.make }}</option> -->
                            </select>
                        </div>
                        
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto mt-3">
                            <select class="form-control" disabled="true" name="models">
                                <option v-if="inmodelid == ''" value="">Alle Modelle</option>
                                <option v-if="inmodelid != ''" value="">{{ inmodel }}</option>
                                <!-- <option v-for="model in this.modelData.models" :key="model.n"  :value="model.i">{{ model.n }}</option> -->
                            </select>
                        </div>

                    </div>
                    <div class="row mt-0">
                        
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto mt-3">
                            <select class="form-control" name="ezfrom" v-model="ezfrom">                                    
                                <option :value="undefined" disabled><span v-if="inezfrom != '0'">Ab {{ inezfrom }}</span><span v-if="inezfrom == '0'">Alle Erstzulassungen</span></option>
                                <option value="2013">Ab 2013</option>
                                <option value="2014">Ab 2014</option>
                                <option value="2015">Ab 2015</option>
                                <option value="2016">Ab 2016</option>
                                <option value="2017">Ab 2017</option>
                                <option value="2018">Ab 2018</option>
                                <option value="2019">Ab 2019</option>
                                <option value="2020">Ab 2020</option>
                                <option value="2021">Ab 2021</option>
                                <option value="2022">Ab 2022</option>
                                <option value="2023">Ab 2023</option>
                            </select>
                        </div>
                        
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto mt-3">
                            <select class="form-control" name="kmto" v-model="kmto">                                
                                <option :value="undefined" disabled><span v-if="inkmto != '0'">Bis {{Number(inkmto).toLocaleString('de-DE') }} km</span><span v-if="inkmto == '0'">Alle Kilometer</span></option>
                                <option value="10000">Bis 10.000 km</option>
                                <option value="20000">Bis 20.000 km</option>
                                <option value="30000">Bis 30.000 km</option>
                                <option value="40000">Bis 40.000 km</option>
                                <option value="50000">Bis 50.000 km</option>
                                <option value="60000">Bis 60.000 km</option>
                                <option value="70000">Bis 70.000 km</option>
                                <option value="80000">Bis 80.000 km</option>
                                <option value="90000">Bis 90.000 km</option>
                                <option value="100000">Bis 100.000 km</option>
                                <option value="150000">Bis 150.000 km</option>
                            </select>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto mt-3">
                            <select class="form-control" name="priceto" v-model="priceto">
                                <option :value="undefined" disabled><span v-if="inpriceto != '0'">Bis {{Number(inpriceto).toLocaleString('de-DE') }} km</span><span v-if="inpriceto == '0'">Alle Preise</span></option>
                                <option value="10000">Bis 10.000 €</option>
                                <option value="20000">Bis 20.000 €</option>
                                <option value="30000">Bis 30.000 €</option>
                                <option value="40000">Bis 40.000 €</option>
                                <option value="50000">Bis 50.000 €</option>
                                <option value="60000">Bis 60.000 €</option>
                                <option value="70000">Bis 70.000 €</option>
                                <option value="80000">Bis 80.000 €</option>
                                <option value="90000">Bis 90.000 €</option>
                                <option value="100000">Bis 100.000 €</option>
                                <option value="150000">Bis 150.000 €</option>
                            </select>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto mt-4">
                            <span class="mt-3"><a @click="saveEditFilter()" class="btn btn-lg btn-success btn-block">Speichern</a></span>
                            <br><br><a @click="closeModal(false)" style="color: #4c4c4c; cursor: pointer;"><b>Abbrechen</b></a>
                        </div>

                    </div>
                    
                  
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import models from "../models";
import makes from '@/config/makes.js'
import { useToast } from 'vue-toast-notification'

export default {
    name: 'BuyStationFilterEdit',
    props: ['showdialog', 'inidfilter', 'inmarke', 'inmarkeid', 'inmodel', 'inmodelid', 'inezfrom', 'inkmto', 'inpriceto'],   
    data() {
        return {            
            data: makes,
            modelData: [],
            marke: 'Alle',
            markeid: '',
            model: 'Alle',
            modelid: '',          
            selected: undefined,
            filters: [],
            ezfrom: undefined,
            kmto: undefined,
            priceto: undefined,
            fid: ""
        }
    }, 
    methods: {
        closeModal(anwser) {
            this.$emit('showdialog', false)            
            this.$emit('answer', anwser)            
        },          
        async saveEditFilter(){
            
            var ez;
            var km;
            var price;
            this.fid = this.inidfilter;
            if(this.ezfrom == undefined){ ez = this.inezfrom; }else{ ez = this.ezfrom; }
            if(this.kmto == undefined){ km = this.inkmto; }else{ km = this.kmto; }
            if(this.priceto == undefined){ price = this.inpriceto; }else{ price = this.priceto; }

            console.log(this.data.idfilter);

            // update request data
            await models.editAnkaufFilter(this.fid, ez, km, price).then(async res => {
                console.log(res.data);
                if(res.data['message'] == "success"){                        
                    this.deleted = true;
                    const toast = useToast();                      
                    toast.open({
                        message: 'Marken Filter gespeichert!',
                        type: 'success',
                        position: 'top-right'
                    });
                    this.$emit('showdialog', false);
                    this.$emit('answer', false);
                    this.$emit('respond', [ez, km, price]);
                }                
            }).catch(err => {      
                console.log(err);
            });
            
            
             
        } 
    },
    mounted() {
    }
}
</script>

<style scoped>
.dialogview{
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;
    z-index: 99999;
}

.dialogbox{
    margin: auto;
    margin-top: 14%;
    width: 550px;
    height: auto;
    background-color: #fff;
    border-radius: 15px;
    padding: 45px;
}
.loading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 99999;
    padding-top: 0px;
}
</style>