import axios from "axios";

const getHome = async (uid) => {
  const body = { uid: uid };       
  return await axios.post(
    'https://www.sehrguterpreis.de/api/4pi2.php?api=getpublichomesgp&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y', 
    JSON.stringify(body), 
    { headers: { 'Content-Type': 'application/json' } }
  ).catch(err => {
    console.log(err);
  }); 
}

const getCarById = async (cid, uid) => {
  const body = { carid: cid, uid: uid };       
  return await axios.post(
    'https://www.sehrguterpreis.de/api/4pi2.php?api=getcarbyid&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y', 
    JSON.stringify(body), 
    { headers: { 'Content-Type': 'application/json' } }
  ).catch(err => {
    console.log(err);
  }); 
}

const getParking = async (uid) => {
  const body = { uid: uid };       
  return await axios.post(
    'https://www.sehrguterpreis.de/api/4pi2.php?api=getuserparkingnew&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y', 
    JSON.stringify(body), 
    { headers: { 'Content-Type': 'application/json' } }
  ).catch(err => {
    console.log(err);
  }); 
}

const parkCar = async (uid, mid) => 
{   
  const body = { uid: uid, mid: mid };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=insertusercarnew&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
} 

const parkCarOut = async (uid, mid) => 
{   
  const body = { uid: uid, mid: mid };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=deleteusercarnew&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
} 

const getMessages = async (uid) => 
{
  const body = { uid: uid };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getusermessagesweb&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const sendMessages = async (uid, mid, wishprice) => 
{
  const body = { uid: uid, mid: mid, wishprice: wishprice };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=insertmessage&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }  
}

const deleteMessage = async (mtoken) => 
{
  const body = { mtoken: mtoken };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=deletemessage&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }  
}

const acceptCounterOffer = async (uid, mtoken, price) => 
{
  const body = { uid: uid, mtoken: mtoken, price: price };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=acceptcounteroffer&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }  
}

const declineCounterOffer = async (uid, mtoken, price) => 
{
  const body = { uid: uid, mtoken: mtoken, price: price };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=declinecounteroffer&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }  
}

const buyCar = async (uid, id, delivery, distance, autocontract, gutachten, treuhand) => 
{
  const body = { uid: uid, id: id, delivery: delivery, distance: distance, autocontract: autocontract, gutachten: gutachten, treuhand: treuhand };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=userbuy&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }  
}

const getSales = async (uid) => 
{
  const body = { uid: uid };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getusersales&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const setPaid = async (uid, stoken) => 
{
  const body = { uid: uid, stoken: stoken };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=setcaronpaid&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const closeDeal = async (uid, stoken, signatur) => 
{
  const body = { uid: uid, stoken: stoken, signatur: signatur };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=userclosedeal&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const cancelDeal = async (uid, stoken) => 
{
  const body = { uid: uid, stoken: stoken };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=userstornodeal&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getInvoices = async (uid) => 
{
  const body = { uid: uid };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getuserbills&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getlogistcOrders = async (uid) => 
{
  const body = { uid: uid };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getlogisticorders&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const booklogistcOrders = async (uid, form) => 
{
  const body = { uid: uid, data: form };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=booklogisticorder&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getUserChats = async (uid) => 
{
  const body = { uid: uid };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getuserchats&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getUserChatMessages = async (uid, ctoken) => 
{
  const body = { uid: uid, ctoken: ctoken };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getchatmessages&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}
const addChatMessage = async (uid, ctoken, message, type) => 
{
  const body = { sender: uid, ctoken: ctoken, message: message, message_type: type };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=addchatmessage&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const deleteChatMessage = async (ctoken) => 
{
  const body = { ctoken: ctoken };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=deletechatmessage&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getUserChatsById = async (carid, uid) => 
{
  const body = { carid: carid, uid: uid };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getuserchatsbyid&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getUserAppointments = async (uid) => 
{
  const body = { uid: uid };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getankauftermine&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const addUserAsAnkaufStation = async (uid) => 
{
  const body = { uid: uid };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=adduserasankaufstation&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const activedAnkaufstation = async (uid, status) => 
{
  const body = { uid: uid, status: status };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=activedankaufstation&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const setTerminConfirmation = async (atoken, status, feedback) => 
{
  const body = { atoken: atoken, status: status, feedback: feedback };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=setterminconfirmation&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const setPostponeAppointment = async (atoken, status, newdatetime) => 
{
  const body = { atoken: atoken, status: status, newdatetime: newdatetime };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=postponeappointment&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const setTerminPurchased = async (atoken, status, feedback) => 
{
  const body = { atoken: atoken, status: status, feedback: feedback };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=setterminpurchased&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getAnkaufFilter = async (uid) => 
{
  const body = { uid: uid };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getankauffilter&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const addAnkaufFilter = async (uid, make, makeid, model, modelid, ezfrom, kmto, priceto) => 
{
  const body = { uid: uid, make: make, makeid: makeid, model: model, modelid: modelid, ezfrom: ezfrom, kmto: kmto, priceto: priceto };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=addankauffilter&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const deleteAnkaufFilter = async (uid, id) => 
{
  const body = { uid: uid, id: id };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=deleteankauffilter&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const editAnkaufFilter = async (fid, ez, km, price) => 
{
  const body = { fid: fid, ez: ez, km: km, price: price };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=editankauffilter&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getAnkaufOpenTimes = async (uid) => 
{
  const body = { uid: uid };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getankaufopentimes&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const updateAnkaufOpenTimes = async (uid, data) => 
{
  const body = { uid: uid, data: data };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=updateankaufopentimes&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const resetPassword = async (email) => 
{
  const body = { email: email };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=resetpassword&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getlocations = async (fromzip, tozip) => 
{
  const body = { fromzip: fromzip, tozip: tozip };     
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=getlocations&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getStock = async (sellerid) => 
{   
  try {
    return await axios.get('https://www.sehrguterpreis.de/api/stock_get.php?sellerid='+sellerid,{ headers: { 'Content-Type': 'application/json' } });
  } catch (err) {
    console.log(err);
  }
}

const countCars = async (uid, markeid, modelid, preisvalue, kilometervalue, kraftstoffvalue, getriebevalue, ezvalue, sortvalue, mwst, umkreisactive, searchplz, distance ) => 
{   
  const body = { 
    uid: uid,     
    make: markeid,
    model: modelid,
    priceto: preisvalue,
    kmto: kilometervalue,
    fuel: kraftstoffvalue,
    gearbox: getriebevalue,
    firstreg: ezvalue,
    sort: sortvalue,
    mwst: mwst,
    umkreisactive: umkreisactive,
    searchplz: searchplz,
    distance: distance
  };
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=countcars&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getSearch = async (uid, markeid, modelid, preisvalue, kilometervalue, kraftstoffvalue, getriebevalue, ezvalue, sortvalue, mwst, umkreisactive, searchplz, distance ) => 
{   
  const body = { 
    uid: uid,     
    make: markeid,
    model: modelid,
    priceto: preisvalue,
    kmto: kilometervalue,
    fuel: kraftstoffvalue,
    gearbox: getriebevalue,
    firstreg: ezvalue,
    sort: sortvalue,
    mwst: mwst,
    umkreisactive: umkreisactive,
    searchplz: searchplz,
    distance: distance
  };   
  try {
    return await axios.post('https://www.sehrguterpreis.de/api/4pi2.php?api=detailsearchcarsweb&token=yHhMWa5rY3KcT4myn2m4NUEY1nJL1ct98OpyAORbIbI9Z5S8QBVNpLUQA0BSJ3FYLXVRT4fPA5T8y',
      JSON.stringify(body),
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.log(err);
  }
}

const getDistance = async (lat1, lng1, lat2, lng2) => 
{
    const radius = 6371000 // Earth's radius in km
    const dLat = toRadians(lat2 - lat1)
    const dLng = toRadians(lng2 - lng1)
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distance = radius * c
    return ((distance / 1000) * 1.25).toFixed(0)
}

function toRadians(degree) {
  return degree * (Math.PI / 180)
}

const getDeliveryCosts = (distance) =>
{
  var out
  if(distance <= 100) {
      out = {'net': "220.00", 'gross': "261.80"}
  }else if(distance <= 200) {
      out = {'net': "345.00", 'gross': "410.55"}
  }else if(distance <= 300) {
      out = {'net': "400.00", 'gross': "476.00"}
  }else if(distance <= 400) {
      out = {'net': "460.00", 'gross': "547.40"}
  }else if(distance <= 500) {
      out = {'net': "515.00", 'gross': "612.85"}
  }else if(distance <= 600) {
      out = {'net': "560.00", 'gross': "666.40"}
  }else if(distance <= 700) {    
      out = {'net': "616.00", 'gross': "733.04"}
  }else if(distance <= 800) {
      out = {'net': "660.00", 'gross': "785.40"}
  }else if(distance <= 1000) {
      out = {'net': "770.00", 'gross': "916.30"}
  }else if(distance <= 1200) {
      out = {'net': "880.00", 'gross': "1047.20"}
  }else if(distance <= 1500) {
      out = {'net': "1100.00", 'gross': "1309.00"}
  }else{
      out = {'net': "0", 'gross': "0"}
  }
  return out
}

const getDeliveryCostsPremium = (distance) =>
{
  var out
  if(distance <= 100) {
      out = {'net': "500.00", 'gross': "595.00"}
  }else if(distance <= 200) {
      out = {'net': "750.00", 'gross': "892.50"}
  }else if(distance <= 300) {
      out = {'net': "1000.00", 'gross': "1190.00"}
  }else if(distance <= 400) {
      out = {'net': "1300.00", 'gross': "1547.00"}
  }else if(distance <= 500) {
      out = {'net': "1400.00", 'gross': "1666.00"}
  }else if(distance <= 600) {
      out = {'net': "1500.00", 'gross': "1785.00"}
  }else if(distance <= 700) {    
      out = {'net': "1600.00", 'gross': "1904.00"}
  }else if(distance <= 800) {
      out = {'net': "1700.00", 'gross': "2023.00"}
  }else if(distance <= 1000) {
      out = {'net': "1800.00", 'gross': "2142.30"}
  }else if(distance <= 1200) {
      out = {'net': "1900.00", 'gross': "2261.00"}
  }else if(distance <= 1500) {
      out = {'net': "2300.00", 'gross': "2737.00"}
  }else{
      out = {'net': "0", 'gross': "0"}
  }
  return out
}

const getSGPServiceCosts = (value) =>
{
  var out
  if(value <= 7000) {
      out = {'net': "100.00", 'gross': "119.00"}
  }else if(value <= 10000) {      
      out = {'net': "100.00", 'gross': "119.00"}
  }else if(value <= 15000) {
      out = {'net': "150.00", 'gross': "178.50"}
  }else if(value <= 20000) {      
      out = {'net': "200.00", 'gross': "238.00"}
  }else if(value <= 25000) {
      out = {'net': "250.00", 'gross': "297.50"}
  }else if(value <= 30000) {
      out = {'net': "300.00", 'gross': "257.00"}
  }else if(value <= 35000) {
      out = {'net': "350.00", 'gross': "416.50"}
  }else if(value <= 45000) {
      out = {'net': "400.00", 'gross': "476.00"}
  }else if(value <= 60000) {
      out = {'net': "450.00", 'gross': "535.50"}
  }else if(value <= 80000) {      
      out = {'net': "500.00", 'gross': "595.00"}
  }else if(value <= 150000) {
      out = {'net': "550.00", 'gross': "654.50"}
  }else if(value <= 500000) {      
      out = {'net': "600.00", 'gross': "714.00"}
  }else{
      out = {'net': "1000.00", 'gross': "1190.00"}
  }
  return out
}

export default {
  name: 'models',
  getHome,
  getCarById,
  parkCar,    
  getParking,
  parkCarOut,
  getMessages,
  sendMessages,
  deleteMessage,
  acceptCounterOffer,
  declineCounterOffer,
  buyCar,
  getSales,
  setPaid,
  closeDeal,
  cancelDeal,
  getInvoices,
  getlogistcOrders,
  booklogistcOrders,
  getUserChats,
  getUserChatMessages,
  addChatMessage,
  deleteChatMessage,
  getUserChatsById,
  getUserAppointments,
  addUserAsAnkaufStation,
  activedAnkaufstation,
  setTerminConfirmation,
  setPostponeAppointment,
  setTerminPurchased,
  getAnkaufFilter,
  addAnkaufFilter,
  deleteAnkaufFilter,
  editAnkaufFilter,
  getAnkaufOpenTimes,
  updateAnkaufOpenTimes,
  resetPassword,
  getlocations,
  getStock,
  countCars,
  getSearch,
  getDistance,
  getDeliveryCosts,
  getDeliveryCostsPremium,
  getSGPServiceCosts
};