<template>
    <div v-if="deleted == false" class="row" style="font-size: 14px;">                
                   
        <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {{ data.make }}
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {{ data.model }}
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <span v-if="data.ez_from != 0">Ab {{ data.ez_from }}</span>
            <span v-else>Alle</span>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <span v-if="data.km_to != 0">Bis {{ data.km_to }} km</span>
            <span v-else>Alle</span>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <span v-if="data.price_to != 0">Bis {{ data.price_to }} €</span>
            <span v-else>Alle</span>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2" style="text-align: center; padding-right:20px;">
            <img @click="deleteFilter()" src="../assets/icons/trash-can-red.svg" width="20" alt="">
        </div> -->

        <table style="font-size: 16px;">
            <tr>
                <td width="15%" style="padding-left:10px;">{{ data.make }}</td>
                <td width="15%">{{ data.model }}</td>
                <td width="15%"><span v-if="ez != '0'">Ab {{ ez }}</span><span v-if="ez == '0'">Alle</span></td>
                <td width="15%"><span v-if="km != '0'">Bis {{ Number(km).toLocaleString('de-DE') }} km</span><span v-if="km == '0'">Alle</span></td>
                <td width="15%"><span v-if="price != '0'">Bis {{ Number(price).toLocaleString('de-DE') }} €</span><span v-if="price == '0'">Alle</span></td>
                <td width="15%" style="text-align: center;cursor: pointer;"><img @click="editFilter(true)" src="../assets/icons/pencil.svg" width="20" alt=""></td>
                <td width="10%" style="text-align: center;cursor: pointer;"><img @click="deleteFilter()" src="../assets/icons/trash-can-red.svg" width="20" alt=""></td>
            </tr>
        </table>
        <BuyStationFilterEdit 
            v-bind:showdialog="editDialog" 
            v-bind:inidfilter="data.idfilter" 
            v-bind:inmarke="data.make" 
            v-bind:inmarkeid="data.makeid" 
            v-bind:inmodel="data.model" 
            v-bind:inmodelid="data.modelid" 
            v-bind:inezfrom="ez" 
            v-bind:inkmto="km" 
            v-bind:inpriceto="price" 
            @answer="(i) => editFilter(i)"
            @respond="(i) => setEditValues(i)"
        />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <hr>
        </div>

    </div>        
</template>

<script>

import models from "../models";
import { useToast } from 'vue-toast-notification'
import BuyStationFilterEdit from '@/components/BuyStationFilterEdit'

export default {
    name: 'BuyStationFilterItemView',
    components: {
        BuyStationFilterEdit
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        },   
    },
    data() {
        return {
            deleted: false,
            editDialog: false,
            ez: '',
            km: '',
            price: ''
        }
    }, 
    methods: {        
        async deleteFilter()
        {
            await models.deleteAnkaufFilter(this.$store.getters.StateToken, this.data.idfilter).then(async res => {
                // console.log(res.data);
                if(res.data['message'] == "success"){                        
                    this.deleted = true;
                    const toast = useToast();                      
                    toast.open({
                        message: 'Marken Filter gelöscht!',
                        type: 'error',
                        position: 'top-right'
                    });
                }                
            }).catch(err => {      
                console.log(err);
            });
        },
        async editFilter(i){
            this.editDialog = i;
        },
        async setEditValues(i){
            this.ez = i[0];
            this.km = i[1];
            this.price = i[2];            
        }
    },
    mounted() {
        this.ez = this.data.ez_from;
        this.km = this.data.km_to;
        this.price = this.data.price_to;
    }
}
</script>

<style scoped>

</style>