<template>
    <div v-if="show">
        <transition name="modal" id="modal-template">            
            <div>
                <div @click="closeModal()" class="close">
                    <img src="@/assets/icons/times.svg" alt="">
                </div>                        
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container-lg">                                                                    
                            <div class="modal-body-lg text-left">
                                
                                <div class="row mt-5">
                                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">
                                        
                                        <div class="row">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto">
                                                <h3>Fahrzeug Filter hinzufügen</h3>
                                                <hr>
                                            </div>
                                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 mx-auto">
                                                <select @change="onChangeMake($event)" class="form-control" name="makes" v-model="selected">                                    
                                                    <option :value="undefined">Alle Marken</option>
                                                    <option v-for="make in this.data.makedata" :key="make.makeid"  :value="make.makeid">{{ make.make }}</option>
                                                </select>
                                            </div>
                                            
                                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 mx-auto">
                                                <select class="form-control" disabled="true" @change=" onChangeModel($event)" name="models">
                                                    <option value="">Alle Modelle</option>
                                                    <option v-for="model in this.modelData.models" :key="model.n"  :value="model.i">{{ model.n }}</option>
                                                </select>
                                            </div>

                                            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 mx-auto" style="padding-top:5px;">
                                                <span @click="addFilter()" style="cursor:pointer;font-size:16px;padding: 8px 16px 9px 16px;background-color: green;border-radius: 10px;color:#fff;">Hinzufügen</span>
                                            </div>

                                        </div>
                                        <div class="row mt-4">
                                            
                                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                                <select class="form-control" name="ezfrom" v-model="ezfrom">                                    
                                                    <option :value="undefined">Alle Erstzulassungen</option>
                                                    <option value="2013">Ab 2013</option>
                                                    <option value="2014">Ab 2014</option>
                                                    <option value="2015">Ab 2015</option>
                                                    <option value="2016">Ab 2016</option>
                                                    <option value="2017">Ab 2017</option>
                                                    <option value="2018">Ab 2018</option>
                                                    <option value="2019">Ab 2019</option>
                                                    <option value="2020">Ab 2020</option>
                                                    <option value="2021">Ab 2021</option>
                                                    <option value="2022">Ab 2022</option>
                                                    <option value="2023">Ab 2023</option>
                                                </select>
                                            </div>
                                            
                                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                                <select class="form-control" name="kmto" v-model="kmto">
                                                    <option :value="undefined">Alle Kilometer</option>
                                                    <option value="10000">Bis 10.000 km</option>
                                                    <option value="20000">Bis 20.000 km</option>
                                                    <option value="30000">Bis 30.000 km</option>
                                                    <option value="40000">Bis 40.000 km</option>
                                                    <option value="50000">Bis 50.000 km</option>
                                                    <option value="60000">Bis 60.000 km</option>
                                                    <option value="70000">Bis 70.000 km</option>
                                                    <option value="80000">Bis 80.000 km</option>
                                                    <option value="90000">Bis 90.000 km</option>
                                                    <option value="100000">Bis 100.000 km</option>
                                                    <option value="150000">Bis 150.000 km</option>
                                                </select>
                                            </div>

                                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 mx-auto">
                                                <select class="form-control" name="priceto" v-model="priceto">                                    
                                                    <option :value="undefined">Alle Preise</option>
                                                    <option value="10000">Bis 10.000 €</option>
                                                    <option value="20000">Bis 20.000 €</option>
                                                    <option value="30000">Bis 30.000 €</option>
                                                    <option value="40000">Bis 40.000 €</option>
                                                    <option value="50000">Bis 50.000 €</option>
                                                    <option value="60000">Bis 60.000 €</option>
                                                    <option value="70000">Bis 70.000 €</option>
                                                    <option value="80000">Bis 80.000 €</option>
                                                    <option value="90000">Bis 90.000 €</option>
                                                    <option value="100000">Bis 100.000 €</option>
                                                    <option value="150000">Bis 150.000 €</option>
                                                </select>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                               
                                
                                <div class="row mt-5">
                                    <div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 mx-auto">

                                        <div v-if="loading == true" class="col-lg-12 text-center">
                                            <img src="@/assets/bx_loader.gif" width="35" height="35" style="margin-top: 27%;">
                                        </div>
                                        <div v-else-if="loading == false">
                                            
                                            <div v-if="filters.length == 0" class="text-center">
                                                <img src="../assets/icons/filter.svg" width="120" style="margin-top:0px;">
                                                <br>
                                                <br>
                                                <h4>Jetzt ersten Filter hinzufügen.</h4>
                                                <p style="font-size: 16px;">Bei SehrGuterPreis möchten wir sicherstellen, dass Sie genau die Ankauftermine erhalten, <br>
                                                   die Sie wirklich benötigen. Deshalb bieten wir Ihnen die Möglichkeit, bis zu 20 individuelle Filter<br>
                                                   zu speichern. Ganz nach Ihren Bedürfnissen und Wünschen.
                                                </p>
                                                <p style="font-size: 16px;">
                                                Modelle sind optional, damit Sie die Filter ganz flexibel anpassen können. So erhalten Sie<br>
                                                maßgeschneiderte Ankauftermine, die perfekt zu Ihrem Autohandel passen.
                                                </p>
                                                <p style="font-size: 16px;">
                                                    <b>Wenn Sie Ankauf-Termine für alle Marken und alle Modelle bekommen wollen.<br>Fügen Sie einfach KEINEN Filter hinzu!</b>
                                                </p>
                                            </div>
                                            <div v-else>
                                                <div class="row">   
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <hr>
                                                    </div>       
                                                    
                                                    <table style="font-size: 16px; font-weight: 600;">
                                                        <tr>
                                                            <td width="15%" style="padding-left:10px;">Marke</td>
                                                            <td width="15%">Modell</td>
                                                            <td width="15%">EZ ab</td>
                                                            <td width="15%">KM bis</td>
                                                            <td width="15%">Preis bis</td>
                                                            <td width="15%" style="text-align: center;">Bearbeiten</td>
                                                            <td width="10%" style="text-align: center;">Löschen</td>
                                                        </tr>
                                                    </table>

                                                    <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                        <b>Marke</b>
                                                    </div>
                                                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                        <b>Modell</b>
                                                    </div>
                                                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                        <b>EZ ab</b>
                                                    </div>
                                                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                        <b>KM bis</b>
                                                    </div>
                                                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                        <b>Preis bis</b>
                                                    </div>
                                                    <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="text-align: center;" >
                                                        <b>Löschen</b>
                                                    </div>
                                                    <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1" style="text-align: center;">
                                                        <b>Bearbeiten</b>
                                                    </div> -->
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <hr>
                                                    </div>
                                                </div>     
                                                <div v-for="filter in filters" :key="filter.idfilter">
                                                    <BuyStationFilterItem v-bind:data="filter" />
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <div style="margin-top:10px;">
        <a @click="openModal()" class="detailsBtn mt-2">
            Fahrzeug Filter
        </a>
    </div>
</template>

<script>

import models from "../models";
import { useToast } from 'vue-toast-notification'
import makes from '@/config/makes.js'
import BuyStationFilterItem from '@/components/BuyStationFilterItem'

export default {
    name: 'BuyStationFilterView', 
    components: {
        BuyStationFilterItem
    },
    props: {
    },
    data() {
        return {
            loading: true,
            show: false,
            data: makes,
            modelData: [],
            marke: 'Alle',
            markeid: '',
            model: 'Alle',
            modelid: '',          
            selected: undefined,
            filters: [],
            ezfrom: undefined,
            kmto: undefined,
            priceto: undefined,
        }
    },
    mounted() {     
        this.loadFilter();
    },
    methods: {        
        openModal(){
            this.show = true;
            document.body.classList.add('noscroll');
        },
        closeModal(){
            this.show = false;
            document.body.classList.remove('noscroll')
            this.loadFilter();
        },
        getJsonFile (index) {
            this.modelData = require('../assets/carmodels/' + index + '.json')
        },
        async onChangeMake(event) {
            this.markeid = event.target.value
            this.marke = event.target.options[event.target.selectedIndex].text  
            if(event.target.value != ""){                                   
                this.modelid = ''
                this.model = 'Alle'    
                this.modelData = []        
                this.getJsonFile(this.markeid)
                document.getElementsByName("models")[0].disabled = false
            }else{
                this.modelid = ''
                this.model = 'Alle'
                this.modelData = []
                document.getElementsByName("models")[0].disabled = true
            }
        },
        onChangeModel(event) {            
            if(event.target.value != ""){
                this.modelid = event.target.value
                this.model = event.target.options[event.target.selectedIndex].text
            }else {
                this.modelid = ''
                this.model = 'Alle'
                this.modelData = []
            }            
        },
        async loadFilter()
        {
            await models.getAnkaufFilter(this.$store.getters.StateToken).then(async res => {
                // console.log(res.data);
                if(res.data['message'] == "success"){
                    this.filters = res.data['filter'];              
                }                
                this.loading = false;
            }).catch(err => {      
                this.loading = false;
                console.log(err);
            });
        },
        async addFilter()
        { 
            if(this.marke != 'Alle' )
            {
                this.loading = true;
                await models.addAnkaufFilter(this.$store.getters.StateToken, this.marke, this.markeid, this.model, this.modelid, this.ezfrom, this.kmto, this.priceto).then(async res => {
                    console.log(res.data);
                    if(res.data['message'] == "success"){
                        this.selected = undefined;
                        this.markeid = '';
                        this.marke = 'Alle'; 
                        this.modelid = '';
                        this.model = 'Alle';    
                        this.modelData = [];
                        this.ezfrom = undefined;
                        this.kmto = undefined;
                        this.priceto = undefined;
                        document.getElementsByName("models")[0].disabled = true;                              
                        this.loadFilter();
                    }                
                    this.loading = false;
                }).catch(err => {      
                    this.loading = false;
                    console.log(err);
                });
            }else{
                const toast = useToast();                      
                toast.open({
                    message: 'Wählen Sie eine Marke aus!',
                    type: 'error',
                    position: 'top-right'
                });
            }
        }
    }
}
</script>

<style>

.close{
    position: fixed;
    display: block;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    z-index: 99999999;
}

.feature-tag{
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 10px;       
    color: #000;
    border:1px solid #dedede;
    display:block;
    float:left; 
    margin-right: 10px; 
    margin-bottom: 10px; 
    font-size: 13px;
}

.detailsBtn{
    display: block;
    width: 100%;
    cursor: pointer; 
    margin-top: 10px; 
    color:#454545; 
    text-decoration: none; 
    font-size: 16px;
    font-weight: 600;
    padding: 6px 20px 6px 20px;
    border: 1px solid #dedede;
    border-radius: 15px;
}
.detailsBtn:hover {
    color: #000;
    background-color: #dedede;
}
</style>
<style scoped src="../assets/css/car.css"></style>